import React, { useState, useMemo } from "react";
import {
	FACEBOOK,
	Platform,
	TIKTOK,
	YOUTUBE,
	calculateInstagramRate,
	calculateTikTokRate,
	calculateYoutubeRate,
} from "@withjuly/fabric";
import {
	Button,
	Heading,
	Modal,
	ModalBody,
	modalSizes,
	Text,
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	useConfetti,
} from "@withjuly/solis";
import {
	ArrowLeft,
	ArrowRight,
	Check,
	FacebookLogo,
	InstagramLogo,
	TiktokLogo,
	Warning,
	YoutubeLogo,
} from "@withjuly/julycons";
import { useConnectAccount } from "~/utils/hooks/oauth";
import { useRouter } from "next/router";
import { cx, getApiError } from "@withjuly/frontend-common";
import Link from "next/link";
import { useCreator } from "~/utils/context/creator";
import { trpc } from "../Utility/trpc";

interface ConnectSocialsProps {
	isInstagramRequired?: boolean;
}

export const ConnectSocials: React.FC<ConnectSocialsProps> = ({
	isInstagramRequired = true,
}) => {
	const { creatorProfile } = useCreator();
	const [platformConnectFailed, setPlatformConnectFailed] = useState<
		Platform | undefined
	>();
	const [connectInstagramModalOpen, setConnectInstagramModalOpen] = useState(
		() => false,
	);
	const instagramConnection = useConnectAccount(
		FACEBOOK,
		() => setPlatformConnectFailed(() => undefined),
		(error) => {
			const apiError = getApiError(error);
			if (apiError) {
				if (
					apiError.name === "connected_account_missing_scopes" ||
					apiError.name === "no_accounts_eligible_to_connect"
				) {
					setPlatformConnectFailed(() => Platform.INSTAGRAM);
				}
			}

			return false;
		},
	);
	const tiktokConnection = useConnectAccount(TIKTOK);
	const youTubeConnection = useConnectAccount(YOUTUBE);
	const confetti = useConfetti();
	const router = useRouter();
	const utils = trpc.useContext();
	const updateCreator = trpc.creator.update.useMutation({
		onSuccess: () => {
			utils.user.current.invalidate();
			confetti();
			if (typeof router.query.bwb === "string") {
				router.push(`/discover?bwb=${router.query.bwb}`);
			} else {
				router.push("/discover");
			}
		},
	});

	const connectInstagramModal = (
		<Modal
			isOpen={connectInstagramModalOpen}
			setIsOpen={(isOpen) => setConnectInstagramModalOpen(() => isOpen)}
			size={modalSizes[480]}
			content={
				<>
					<ModalBody>
						<div className="flex flex-col gap-8 pt-8">
							<div className="flex flex-col items-center justify-center gap-8">
								<Heading variant="h5" className="text-center">
									You’ll be using your Facebook Business Page to connect your
									Instagram Account
								</Heading>
								<div className="flex items-center gap-3">
									<FacebookLogo width="32px" height="auto" />
									<ArrowRight />
									<InstagramLogo width="32px" height="auto" />
								</div>

								<div className="flex flex-col gap-1">
									<Text className="text-center">
										Not sure if you have a Facebook page connected?
									</Text>
									<Link
										target="_blank"
										href="https://withjuly.notion.site/Connecting-your-IG-and-FB-0056ea46114248c49757bb3c3bff5037"
									>
										<Text className="text-center underline">
											Here’s how to connect yours →
										</Text>
									</Link>
								</div>
							</div>
							<Button
								size="large"
								full
								onClick={() => {
									setConnectInstagramModalOpen(() => false);
									instagramConnection.onConnect();
								}}
							>
								Continue
							</Button>
						</div>
					</ModalBody>
				</>
			}
		/>
	);

	if (platformConnectFailed) {
		return (
			<div
				key="error"
				className="animate-open-fade-move-down flex h-full w-full flex-col items-center justify-between gap-8 bg-gray-900 px-6 py-8 md:justify-center"
			>
				<div className="flex flex-col items-center gap-2">
					<Warning className="text-warning-300 h-8 w-8" />
					<h1 className="text-h4 font-agrandir">Couldn’t connect Instagram</h1>
				</div>
				<div className="flex flex-col gap-3">
					<div className="flex flex-col gap-6 rounded-lg border border-gray-500 bg-gray-700 p-4">
						<p>To connect your account, make sure you have:</p>
						<ul className="flex flex-col gap-1">
							<li>Accepted all permissions for July</li>
							<li>Set up a working Facebook Business Page</li>
							<li>Selected the correct Facebook Business Page</li>
						</ul>
					</div>
				</div>
				<p>
					Still having trouble?{" "}
					<span>
						<a
							href="https://withjuly.notion.site/Connecting-your-IG-and-FB-0056ea46114248c49757bb3c3bff5037"
							target="_blank"
							rel="noreferrer"
							className="underline"
						>
							<span>Read our article </span>
							<span>
								<ArrowRight className="inline" />
							</span>
						</a>
					</span>
				</p>
				<div className="flex w-full items-center justify-center gap-3">
					<Button
						onClick={(e) => {
							e.preventDefault();
							setPlatformConnectFailed(() => undefined);
						}}
						variant="secondary"
						className="h-[42px] w-[170px]"
					>
						<div className="flex items-center justify-center gap-2">
							<ArrowLeft />
							Back
						</div>
					</Button>
					<Button
						type="submit"
						size="large"
						variant="primary"
						onClick={instagramConnection.onConnect}
						className="h-[42px] w-[170px]"
					>
						Try again
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			{connectInstagramModal}
			<div className="flex h-full w-full items-center justify-center bg-gray-800">
				<div
					key="content"
					className="animate-open-fade-move-down flex h-full w-full max-w-[326px] flex-col items-center justify-center gap-6 self-center md:max-w-[480px]"
				>
					<div className="flex h-auto flex-col items-center justify-center gap-12 md:gap-8">
						<div className="flex flex-col items-center justify-center gap-5">
							<div className="flex flex-col items-center gap-2">
								<h1 className="text-h4 font-agrandir text-center">
									Connect Your Socials to get Started
								</h1>
							</div>
							<div className="flex flex-col gap-3">
								<div className="flex w-full flex-col items-center justify-center gap-3 md:max-w-lg md:flex-row">
									<SocialMediaCard
										platform={Platform.INSTAGRAM}
										connected={instagramConnection.isConnected}
										connect={() => setConnectInstagramModalOpen(() => true)}
										loading={instagramConnection.isLoading}
										required={isInstagramRequired}
									/>
									<SocialMediaCard
										platform={Platform.TIKTOK}
										connected={tiktokConnection.isConnected}
										connect={tiktokConnection.onConnect}
										loading={tiktokConnection.isLoading}
									/>
									<SocialMediaCard
										platform={Platform.YOUTUBE}
										connected={youTubeConnection.isConnected}
										connect={youTubeConnection.onConnect}
										loading={youTubeConnection.isLoading}
									/>
								</div>
								<RateCalculator />
							</div>
						</div>
					</div>
					<p className="text-body-sm text-center text-gray-200">
						July can&apos;t see your passwords or private information
					</p>
					<div className="flex w-full items-center justify-center">
						<Tooltip>
							<TooltipTrigger className="flex w-full items-center justify-center">
								<Button
									type="submit"
									size="large"
									variant="primary"
									onClick={() => {
										if (
											creatorProfile &&
											creatorProfile.username !== undefined
										) {
											updateCreator.mutate({
												...creatorProfile,
												username: creatorProfile.username ?? "",
												hasCompletedOnboarding: true,
											});
										}
									}}
									disabled={
										instagramConnection.isLoading ||
										youTubeConnection.isLoading ||
										tiktokConnection.isLoading ||
										(!instagramConnection.isConnected && isInstagramRequired)
									}
									isLoading={updateCreator.isLoading}
									className="w-full px-[48px] py-[14px] md:w-auto"
								>
									Get Started
								</Button>
							</TooltipTrigger>
							<TooltipContent asChild side="bottom">
								{!instagramConnection.isConnected ? (
									<div className="flex items-center justify-center gap-2">
										Connect an instagram account first
									</div>
								) : null}
							</TooltipContent>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
};

interface SocialMediaCardProps {
	platform: Platform;
	connect: () => void;
	connected: boolean;
	required?: boolean;
	loading: boolean;
}
const SocialMediaCard: React.FC<SocialMediaCardProps> = ({
	platform,
	connect,
	required,
	connected,
	loading,
}) => {
	let platformName = "Instagram";
	if (platform === Platform.YOUTUBE) {
		platformName = "YouTube";
	} else if (platform === Platform.TIKTOK) {
		platformName = "TikTok";
	}

	let Icon = InstagramLogo;
	if (platform === Platform.YOUTUBE) {
		Icon = YoutubeLogo;
	} else if (platform === Platform.TIKTOK) {
		Icon = TiktokLogo;
	}

	return (
		<div
			className={cx(
				"shadow-80 flex w-full flex-row items-center justify-between gap-8 rounded-xl border bg-gray-900 p-4 md:flex-col md:justify-center md:px-4 md:pb-4 md:pt-12",
				connected ? "border-blue-600 bg-blue-800" : "border-gray-500",
				required && "border-blue-400",
			)}
		>
			<div className="flex flex-row items-center justify-center gap-4 md:flex-col">
				<Icon className="h-8 w-8" />
				<div className="flex h-11 flex-col items-start justify-center gap-1 md:items-center">
					<p>{platformName}</p>
					<p
						className={cx(
							"text-body-sm text-gray-200",
							required && "text-blue-400",
						)}
					>
						{required ? "Required" : "Optional"}
					</p>
				</div>
			</div>
			{connected ? (
				<div className="flex h-8 items-center justify-center gap-1 md:w-[120px]">
					<p className="text-xs font-medium text-blue-400">Connected</p>
					<Check className="h-3 w-3 text-blue-400" />
				</div>
			) : (
				<Button
					variant={required ? "primary" : "secondary"}
					size="small"
					className="h-8 md:w-[120px]"
					onClick={connect}
					isLoading={loading}
				>
					Connect
				</Button>
			)}
		</div>
	);
};

const RateCalculator: React.FC = () => {
	const { data: profiles } = trpc.profile.getAll.useQuery();

	const totalMonthlyRate = useMemo(() => {
		return profiles?.reduce((acc, profile) => {
			const engagementRate = profile.engagement ?? 0;
			const platformSize = profile.size ?? 0;

			if (profile.platform === Platform.INSTAGRAM) {
				return (
					acc +
					calculateInstagramRate({
						postCount: 3,
						storyCount: 3,
						reelCount: 3,
						engagementRate,
						followers: platformSize,
						terms: {
							isAd: true,
							isRush: false,
							isExclusive: true,
						},
					})
				);
			} else if (profile.platform === Platform.YOUTUBE) {
				return (
					acc +
					calculateYoutubeRate({
						postCount: 3,
						subscribers: platformSize,
						shortCount: 3,
						terms: {
							isAd: true,
							isRush: false,
							isExclusive: true,
						},
					})
				);
			} else if (profile.platform === Platform.TIKTOK) {
				return (
					acc +
					calculateTikTokRate({
						postCount: 3,
						storyCount: 3,
						viewEngagementRate: engagementRate * 25,
						followers: platformSize,
						terms: {
							isAd: true,
							isRush: false,
							isExclusive: true,
						},
					})
				);
			}

			return acc;
		}, 0);
	}, [profiles]);

	return (
		<div className="flex w-full flex-col items-center justify-center gap-4 rounded-xl border border-gray-500 bg-gray-900 p-6">
			<p className="text-gray-100">Check how much you could make on July</p>
			<h2
				className={`text-h3 ${
					totalMonthlyRate ? "text-white-100" : "text-gray-300"
				}`}
			>
				{totalMonthlyRate
					? totalMonthlyRate.toLocaleString("en-US", {
							style: "currency",
							currency: "USD",
						})
					: "$0"}
				/month
			</h2>
			<p className="text-body-sm">Estimated Audience Value</p>
		</div>
	);
};
