import { useState } from "react";

// React hook backed by localstorage
export const useLocalStorage = <T>(
	key: string,
	initialValue: T,
): [T, (value: T | ((_: T) => T)) => void] => {
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (typeof window === "undefined" || !window) {
			return initialValue;
		}

		try {
			const item = window.localStorage.getItem(key);
			return item ? (JSON.parse(item) as T) : initialValue;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
			return initialValue;
		}
	});

	const setValue = (value: T | ((_: T) => T)) => {
		try {
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);

			if (window) {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return [storedValue, setValue];
};
