import React from "react";
import BetaLogo from "~/assets/BetaLogo.svg";
import Link from "next/link";
import { Menu } from "./Menu";

import { AgencySwitcher } from "./Agency/AgencySwitcher";

export interface SideMenuProps {
	setIsHelpCenterOpen: (isOpen: boolean) => void;
	isHelpCenterTooltipOpen: boolean;
	setIsHelpCenterTooltipOpen: (isOpen: boolean) => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({
	setIsHelpCenterOpen,
	isHelpCenterTooltipOpen,
	setIsHelpCenterTooltipOpen,
}) => {
	return (
		<div className="flex h-full w-full">
			<AgencySwitcher />

			<div className="flex h-full w-64 flex-col">
				<div className="flex items-center px-8 pb-0 pt-6">
					<Link
						href="/discover"
						className="rounded transition-all duration-150 ease-in-out"
					>
						<img src={BetaLogo.src} alt="July" className="h-6 w-auto" />
					</Link>
				</div>

				<Menu
					setIsHelpCenterOpen={setIsHelpCenterOpen}
					isHelpCenterTooltipOpen={isHelpCenterTooltipOpen}
					setIsHelpCenterTooltipOpen={setIsHelpCenterTooltipOpen}
				/>
			</div>
		</div>
	);
};
