import {
	Loader,
	Modal,
	modalSizes,
	useConfetti,
	zIndex,
} from "@withjuly/solis";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect, useState } from "react";
import { HelpCenterModal } from "~/components/HelpCenter/HelpCenterModal";
import { MobileHeader } from "~/components/Navigation/MobileHeader";
import { SideMenu } from "~/components/Navigation/SideMenu";
import { ConnectSocials } from "~/components/Onboarding/ConnectSocials";
import { WelcomeModal } from "~/components/ProductTour/WelcomeModal";
import AppReroute from "~/utils/AppReroute";
import { useAgency } from "~/utils/context/agency";
import { useAuth } from "~/utils/context/auth";
import { PitchQueueProvider } from "~/utils/context/pitch-queue";

interface AppLayoutProps {
	children: ReactElement;
}

export const AppLayout: React.FC<AppLayoutProps> = (props) => {
	return (
		<AppReroute allowedAccountTypes={["creator"]}>
			<PitchQueueProvider>
				<AppLayoutInternal {...props} />
			</PitchQueueProvider>
		</AppReroute>
	);
};

export const AppLayoutInternal: React.FC<AppLayoutProps> = ({ children }) => {
	const router = useRouter();
	const confetti = useConfetti();
	const { agencyProfile } = useAgency();
	const userContext = useAuth();

	const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
	const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
	const [hasShownConfetti, setHasShownConfetti] = useState(false);
	const [isHelpCenterTooltipOpen, setIsHelpCenterTooltipOpen] = useState(false);

	const closeWelcomeAndOpenTooltip = () => {
		setIsWelcomeModalOpen(false);
		setIsHelpCenterTooltipOpen(true);
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { onboarded, ...routerQuery } = router.query;
		router.replace({
			query: { ...routerQuery },
		});
	};

	useEffect(() => {
		if (router.isReady && router.query?.onboarded === "true")
			setIsWelcomeModalOpen(true);
	}, [router]);

	useEffect(() => {
		if (
			router.isReady &&
			router.query?.onboarded === "true" &&
			!hasShownConfetti
		) {
			confetti();
			setHasShownConfetti(true);
		}
	}, [router, confetti, hasShownConfetti]);

	if (userContext.isLoading) {
		return (
			<div className="flex h-screen w-screen items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (
		!agencyProfile &&
		userContext.user &&
		!userContext.user.hasCompletedOnboarding
	) {
		return <ConnectSocials isInstagramRequired />;
	}

	return (
		<div className="flex min-h-full">
			<a className="sr-only bg-gray-900" href="#main">
				Skip to content
			</a>
			<nav
				className="hidden w-full border-r border-gray-500 lg:block"
				style={{
					maxWidth: agencyProfile ? "calc(20rem + 2px)" : "calc(16rem + 1px)",
				}}
			>
				<div className="fixed h-full bg-gray-800">
					<SideMenu
						setIsHelpCenterOpen={setIsHelpCenterOpen}
						isHelpCenterTooltipOpen={isHelpCenterTooltipOpen}
						setIsHelpCenterTooltipOpen={setIsHelpCenterTooltipOpen}
					/>
				</div>
			</nav>

			<main className="flex flex-grow flex-col" id="main">
				<div
					className="fixed h-16 w-full border-b border-gray-600 bg-gray-800 lg:hidden"
					style={{
						zIndex: zIndex.nav,
					}}
				>
					<MobileHeader
						setIsHelpCenterOpen={setIsHelpCenterOpen}
						isHelpCenterTooltipOpen={isHelpCenterTooltipOpen}
						setIsHelpCenterTooltipOpen={setIsHelpCenterTooltipOpen}
					/>
				</div>
				<div className="mt-16 h-full bg-gray-900 lg:mt-0">{children}</div>
			</main>

			<Modal
				isOpen={isHelpCenterOpen}
				size={modalSizes[800]}
				setIsOpen={setIsHelpCenterOpen}
				content={<HelpCenterModal setIsOpen={setIsHelpCenterOpen} />}
			/>

			<Modal
				isOpen={isWelcomeModalOpen}
				setIsOpen={closeWelcomeAndOpenTooltip}
				content={<WelcomeModal setIsOpen={closeWelcomeAndOpenTooltip} />}
			/>
		</div>
	);
};
