import {
	Button,
	ModalBody,
	ModalDescription,
	ModalHeader,
	ModalTitle,
	Text,
} from "@withjuly/solis";

interface WelcomeModalProps {
	setIsOpen: (open: boolean) => void;
}

export const WelcomeModal: React.FC<WelcomeModalProps> = ({ setIsOpen }) => {
	return (
		<>
			<ModalHeader>
				<ModalTitle>Welcome to July 🥂</ModalTitle>
				<ModalDescription asChild>
					<Text className="text-gray-100">
						Here’s a quick tour of our most powerful features. For more, visit
						the{" "}
						<a
							href="https://withjuly.notion.site/Welcome-to-July-s-Knowledge-Base-0cd768b001224da78608b6202d67cf20"
							className="text-white-100 underline"
							target="_blank"
							rel="noreferrer"
						>
							knowledge base
						</a>
						.
					</Text>
				</ModalDescription>
			</ModalHeader>
			<ModalBody>
				<div className="flex flex-col gap-6">
					<div className="flex h-80">
						<iframe
							width="100%"
							height="100%"
							src="https://www.youtube.com/embed/w2FvRYaxIv0"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
					<Button size="large" onClick={() => setIsOpen(false)}>
						Skip tutorial
					</Button>
				</div>
			</ModalBody>
		</>
	);
};
