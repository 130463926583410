import { FACEBOOK_API_VERSION } from "@withjuly/backend-common/apis/api-version";
import {
	ConnectableAccount,
	ConnectableAccountProvider,
} from "@withjuly/fabric";
import posthog from "posthog-js";

export const openOAuthDialog = (
	connectable: ConnectableAccount,
	connectionType?: string,
): Window | null => {
	const title = `Connecting ${connectable.provider} | July`;
	const width = 700;
	const height = 900;
	const left = window.screenX + (window.outerWidth - width) / 2;
	const top = window.screenY + (window.outerHeight - height) / 2.5;

	return window.open(
		getOAuthDialogUrl(connectable, connectionType),
		title,
		`width=${width},height=${height},left=${left},top=${top}`,
	);
};

const getOAuthDialogUrl = (
	connectable: ConnectableAccount,
	connectionType?: string,
): string => {
	const proto = window.location.protocol;
	const host = window.location.host;
	const redirect_uri = `${proto}//${host}${connectable.getRedirectPath()}`;

	if (connectable.provider === ConnectableAccountProvider.INSTAGRAM) {
		const stringifiedParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_INSTAGRAM_CLIENT_ID ?? "",
			redirect_uri,
			scope: ["user_profile"].join(","),
			response_type: "code",
		});
		return `https://api.instagram.com/oauth/authorize?${stringifiedParams}`;
	} else if (connectable.provider === ConnectableAccountProvider.FACEBOOK) {
		let scope = [
			"instagram_basic",
			"instagram_manage_insights",
			"pages_show_list",
			"pages_read_engagement",
			"business_management",
			"read_insights",
		];
		const areStoryViewsEnabled = (posthog.getFeatureFlag("story_views") ??
			false) as boolean;
		if (areStoryViewsEnabled) {
			scope.push("pages_manage_metadata");
		}

		if (connectionType === "facebook") {
			scope = [
				"pages_show_list",
				"pages_read_engagement",
				"read_insights",
				"pages_read_user_content",
				"business_management",
			];
		}

		const stringifiedParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID ?? "",
			redirect_uri,
			scope: scope.join(","),
			response_type: "code granted_scopes",
		});
		return `https://www.facebook.com/${FACEBOOK_API_VERSION}/dialog/oauth?${stringifiedParams}`;
	} else if (connectable.provider === ConnectableAccountProvider.GOOGLE) {
		const scopes = [
			"https://www.googleapis.com/auth/userinfo.email",
			"https://www.googleapis.com/auth/userinfo.profile",
			"https://www.googleapis.com/auth/gmail.send",
		];
		const stringifiedParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
			redirect_uri,
			scope: scopes.join(" "),
			response_type: "code",
			access_type: "offline",
			prompt: "consent",
		});
		return `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;
	} else if (connectable.provider === ConnectableAccountProvider.TIKTOK) {
		// Tiktok doesn't let you use localhost as a redirect uri, so we have to be
		// a little hacky here
		let redirectUriHost = location.host;
		if (location.host === "localhost:3000") {
			redirectUriHost = "july.local";
		}

		const stringifiedParams = new URLSearchParams({
			client_key: process.env.NEXT_PUBLIC_TIKTOK_CLIENT_ID ?? "",
			redirect_uri: `${proto}//${redirectUriHost}${connectable.getRedirectPath()}`,
			scope: [
				"user.info.basic",
				"user.info.profile",
				"user.info.stats",
				"video.list",
			].join(","),
			state: Math.random().toString(36).substring(2),
			response_type: "code",
		});
		return `https://www.tiktok.com/v2/auth/authorize?${stringifiedParams}`;
	} else if (connectable.provider === ConnectableAccountProvider.YOUTUBE) {
		const scopes = [
			"https://www.googleapis.com/auth/youtube.readonly",
			"https://www.googleapis.com/auth/userinfo.email",
			"https://www.googleapis.com/auth/yt-analytics.readonly",
		];

		const stringifiedParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
			redirect_uri,
			scope: scopes.join(" "),
			response_type: "code",
			access_type: "offline",
			prompt: "consent",
		});

		return `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;
	} else if (connectable.provider === ConnectableAccountProvider.TWITCH) {
		const stringifiedParams = new URLSearchParams({
			client_id: process.env.NEXT_PUBLIC_TWITCH_CLIENT_ID ?? "",
			redirect_uri,
			scope: ["user:read:email"].join(" "),
			response_type: "code",
		});

		return `https://id.twitch.tv/oauth2/authorize?${stringifiedParams}`;
	} else {
		return "";
	}
};
