import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
} from "react";
import { Brand } from "@withjuly/fabric";
import { useLocalStorage } from "../hooks/localstorage";

export interface PitchQueueContextData {
	brands: Brand[];
	setMatches: (matches: Brand[]) => void;

	addToQueue: (match: Brand) => void;
	setQueue: (matches: Brand[]) => void;
	removeFromQueue: (match: Brand) => void;
	pruneQueue: (currentUuids: string[]) => void;
	isInQueue: (match: Brand) => boolean;
}

export const PitchQueueContext = createContext<PitchQueueContextData>({
	brands: [],
	setMatches: () => 0,

	addToQueue: () => 0,
	setQueue: () => 0,
	removeFromQueue: () => 0,
	pruneQueue: () => 0,
	isInQueue: () => false,
});

export const PITCH_QUEUE_KEY = "JULY_PITCH_QUEUE";

export const PitchQueueProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [brands, setBrands] = useLocalStorage<Brand[]>(PITCH_QUEUE_KEY, []);

	const addToQueue = useCallback(
		(brand: Brand) => {
			if (!brands.some((b) => b.campaign?.uuid === brand.campaign?.uuid)) {
				setBrands((old: Brand[]) => [...old, brand]);
			}
		},
		[brands, setBrands],
	);

	const setQueue = useCallback(
		(brands: Brand[]) => {
			setBrands(brands);
		},
		[setBrands],
	);

	const removeFromQueue = useCallback(
		(brand: Brand) => {
			setBrands(
				brands.filter((b) => b.campaign?.uuid !== brand.campaign?.uuid),
			);
		},
		[brands, setBrands],
	);

	// Removes matches from queue that are no longer in the users current matches
	const pruneQueue = useCallback(
		(currentUuids: string[]) => {
			const prunedQueue = brands.filter((b) =>
				currentUuids.some((uuid) => uuid === b.campaign?.uuid),
			);

			setBrands(prunedQueue);
		},
		[brands, setBrands],
	);

	const isInQueue = useCallback(
		(brand: Brand) => {
			return brands.some((b) => b.campaign?.uuid === brand.campaign?.uuid);
		},
		[brands],
	);

	const [value, setValue] = React.useState<PitchQueueContextData>({
		brands: brands,
		setMatches: setBrands,
		addToQueue,
		setQueue,
		removeFromQueue,
		pruneQueue,
		isInQueue,
	});

	React.useEffect(() => {
		setValue({
			brands: brands,
			setMatches: setBrands,
			addToQueue,
			setQueue,
			removeFromQueue,
			pruneQueue,
			isInQueue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brands]);

	return (
		<PitchQueueContext.Provider value={value}>
			{children}
		</PitchQueueContext.Provider>
	);
};

export const usePitchQueue = () => useContext(PitchQueueContext);
